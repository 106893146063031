import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-items"></a><h2>Psionic Items
    </h2>
    <a id="universal-items"></a><h3>UNIVERSAL ITEMS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p>{`This is a catchall category for any psionic item that doesn`}{`’`}{`t fall into
the other groups. Anyone can use a universal item (unless specified
otherwise in the description).`}</p>
    <a id="universal-item-physical-description"></a><p><span style={{
        "fontWeight": "bold"
      }}>Physical Description:</span>
Varies.</p>
    <a id="physical-item-activation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Activation:</span> Usually
command thought, but details vary from item to item.</p>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><a id="table-minor-universal-items"></a><b>Table: Minor
Universal Items</b>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Market Price
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>01-04
                  </td>
                  <td>Shard (+1, any one skill)
                  </td>
                  <td>10 gp
                  </td>
                </tr>
                <tr>
                  <td>05-07
                  </td>
                  <td>Shard (+2, any one skill)</td>
                  <td>40 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>08-10
                  </td>
                  <td>Crawling tattoo (any 1st level)</td>
                  <td>50 gp
                  </td>
                </tr>
                <tr>
                  <td>11-14
                  </td>
                  <td>Crawling tattoo of concussion</td>
                  <td>50 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>15-18
                  </td>
                  <td>Shard (+3, any one skill)</td>
                  <td>90 gp
                  </td>
                </tr>
                <tr>
                  <td>19-21
                  </td>
                  <td>Shard (+4, any one skill)</td>
                  <td>160 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>22-24
                  </td>
                  <td>Shard (+5, any one skill)</td>
                  <td>250 gp</td>
                </tr>
                <tr>
                  <td>25-28
                  </td>
                  <td>Crawling tattoo (any 2nd level) </td>
                  <td>300 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>29-31
                  </td>
                  <td>Pearl, brain lock</td>
                  <td>300 gp
                  </td>
                </tr>
                <tr>
                  <td>32-35
                  </td>
                  <td>Shard (+6, any one skill)</td>
                  <td>360 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>36-38
                  </td>
                  <td>Shard (+7, any one skill)</td>
                  <td>490 gp
                  </td>
                </tr>
                <tr>
                  <td>39-41
                  </td>
                  <td>Boots of stomping</td>
                  <td>600 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>42-44
                  </td>
                  <td>Shard (+8, any one skill)</td>
                  <td>640 gp
                  </td>
                </tr>
                <tr>
                  <td>45-47
                  </td>
                  <td>Crawling tattoo (any 3rd level) </td>
                  <td>750 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>48-50
                  </td>
                  <td>Crawling tattoo of energy bolt</td>
                  <td>750 gp
                  </td>
                </tr>
                <tr>
                  <td>51-53
                  </td>
                  <td>Pearl, breath crisis</td>
                  <td>750 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>54-56
                  </td>
                  <td>Shard (+9, any one skill)</td>
                  <td>810 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>57-59
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Boots of landing</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>1,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>60-63
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Psionic
restraints, lesser</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>1,000
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>64-67
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Shard (+10, any one
skill) </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>1,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>68-71
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Pearl,
personality parasite</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>1,400
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>72-75
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Crystal mask of
knowledge</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>2,500 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>76-79
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Crystal
mask of languages</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>2,500
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>80-85
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}> Eyes of expanded
vision</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>3,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>86-89
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Gloves
of object reading</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>3,000
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>90-92
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Mirror of suggestion
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>3,600 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>93-94
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Psionic
restraints, average</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>6,000
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>95-97
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Torc of free will</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>6,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>98-100
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Boots
of skating</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>7,000
gp
                  </td>
                </tr>
              </tbody>
            </table>
            <a id="table-major-universal-items"></a><b>Table: Major Universal Items</b>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Market Price
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>01-08
                  </td>
                  <td>Skin of the defender </td>
                  <td>32,000 gp
                  </td>
                </tr>
                <tr>
                  <td>09-16
                  </td>
                  <td>Torc of power preservation</td>
                  <td>36,000 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>17-24
                  </td>
                  <td>Boots of temporal acceleration</td>
                  <td>43,200 gp
                  </td>
                </tr>
                <tr>
                  <td>25-32
                  </td>
                  <td>Third eye repudiate</td>
                  <td>43,200 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>33-40
                  </td>
                  <td>Skin of fiery response</td>
                  <td>60,000 gp
                  </td>
                </tr>
                <tr>
                  <td>41-49
                  </td>
                  <td>Skin of the troll</td>
                  <td>61,200 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>50-57
                  </td>
                  <td>Skin of the hero </td>
                  <td>77,500 gp
                  </td>
                </tr>
                <tr>
                  <td>58-63
                  </td>
                  <td>Skin of the spider</td>
                  <td>79,080 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>64-72
                  </td>
                  <td>Skin of proteus</td>
                  <td>84,000 gp
                  </td>
                </tr>
                <tr>
                  <td>73-80
                  </td>
                  <td>Third eye expose</td>
                  <td>112,000 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>81-87
                  </td>
                  <td>Third eye conceal</td>
                  <td>120,000 gp
                  </td>
                </tr>
                <tr>
                  <td>88-92
                  </td>
                  <td>Third eye dominate</td>
                  <td>120,000 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>93-97
                  </td>
                  <td>Skin of iron</td>
                  <td>129,600 gp
                  </td>
                </tr>
                <tr>
                  <td>98-100
                  </td>
                  <td>Skin of the psion</td>
                  <td>151,000 gp
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <th style={{
            "width": "2%"
          }}> 
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "47%"
          }}><span style={{
              "fontWeight": "bold"
            }}><a id="table-medium-universal-items"></a>Table: Medium Universal Items </span>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Item</th>
                  <th style={{
                    "width": "20%"
                  }}>Market Price
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>01-04
                  </td>
                  <td>Psionatrix of clairsentience
                  </td>
                  <td>8,000 gp</td>
                </tr>
                <tr>
                  <td>05-08
                  </td>
                  <td>Psionatrix of metacreativity</td>
                  <td>8,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>09-12
                  </td>
                  <td>Psionatrix of psychokinesis</td>
                  <td>8,000 gp</td>
                </tr>
                <tr>
                  <td>13-16
                  </td>
                  <td>Psionatrix of psychometabolism</td>
                  <td>8,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>17-20
                  </td>
                  <td>Psionatrix of psychoportation</td>
                  <td>8,000 gp</td>
                </tr>
                <tr>
                  <td>21-25
                  </td>
                  <td>Psionatrix of telepathy</td>
                  <td>8,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>26-27
                  </td>
                  <td>Third eye penetrate</td>
                  <td>8,000 gp</td>
                </tr>
                <tr>
                  <td>28-29
                  </td>
                  <td>Mirror of time hop
                  </td>
                  <td>9,000 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>30-31
                  </td>
                  <td>Crystal mask of detection</td>
                  <td>10,000 gp</td>
                </tr>
                <tr>
                  <td>32-33
                  </td>
                  <td>Crystal mask of discernment</td>
                  <td>10,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>34-35
                  </td>
                  <td>Crystal mask of dread</td>
                  <td>10,000 gp</td>
                </tr>
                <tr>
                  <td>36-38
                  </td>
                  <td>Crystal mask of psionic craft</td>
                  <td>10,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>39-41
                  </td>
                  <td>Ring of self-sufficiency</td>
                  <td>10,000 gp</td>
                </tr>
                <tr>
                  <td>42-43
                  </td>
                  <td>Skin of nimbleness</td>
                  <td>10,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>44-45
                  </td>
                  <td>Third eye aware</td>
                  <td>10,000 gp</td>
                </tr>
                <tr>
                  <td>46-47
                  </td>
                  <td>Third eye concentrate</td>
                  <td>10,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>48-49
                  </td>
                  <td>Third eye gather</td>
                  <td>10,000 gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>50-52
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Eyes of power leech </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>10,180 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>53-55
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Third
eye powerthieve</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>10,180
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>56-58
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Third eye view</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>10,180 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>59-61
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Crystal
mask of mindarmor</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>10,667
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>62-63
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Psionic restraints,
greater</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>12,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>64-65
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Torc
of leech freedom</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>12,000
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>66-68
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Gloves of titan&#8217;s
grip </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>14,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>69-70
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Skin
of the claw</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>16,000
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>71-72
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Amulet of catapsi</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>16,200 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>73-74
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Skin
of the chameleon</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>18,000
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>75-76
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Pearl, mind seed</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>18,500 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>77-78
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Mirror
of mind switch</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>19,800
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>79-80
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Eyes of power leech,
vampiric </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>20,160 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-82
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Crystal
mask of insightful detection</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>20,250
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>83-84
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Crystal anchor, body
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>24,000 gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>85-86
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Crystal
anchor, comprehension</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>24,000
gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>87-88
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Crystal anchor,
creation</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>24,000 gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>89-90
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Crystal
anchor, energy</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>24,000
gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>91
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Crystal anchor, ghost</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>24,000 gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>92-93
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Crystal
anchor, mind</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>24,000
gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>94-95
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Crystal anchor,
travel</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>24,000 gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>96-97
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Psionic
restraints, damping</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>24,000
gp</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>98-100
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Third eye sense</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>24,000 gp</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="universal-item-descriptions"></a><h6>Universal Item Descriptions</h6>
Universal items can be configured to do just about anything.
    <p>Standard universal items are described below.</p>
    <a id="amulet-of-catapsi"></a><p><span style={{
        "fontWeight": "bold"
      }}>Amulet of Catapsi:</span>
This copper amulet aids the wearer against other
psionic characters. Once per day, the wearer can mentally command the <a href="psionicPowersAtoC.html#catapsi" style={{
        "color": "rgb(87, 158, 182)"
      }}>catapsi</a>
power, which blankets a
30-foot-radius area surrounding the
wearer in telepathic noise for 9 rounds. Each time other psionic
characters within this range attempt to manifest a power, they must
succeed on a DC 17 Will save or pay 4 more points than they otherwise
would for each power manifestation. The limit on the number of power
points a subject can spend on a power remains in effect, so the subject
may not be able to manifest his or her highest-level powers.
      <p>{`Moderate telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 9th;
Craft Universal Item, catapsi; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
16,200 gp.`}</p></p>
    <a id="boots-of-landing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Boots of Landing:</span>
These boots allow the wearer to recover instantly
from a fall and can absorb some damage from falling once per day. The
wearer lands on her feet no matter how far she falls, and takes 2 fewer
dice of damage from the fall than normal.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
2nd; Craft Universal Item, catfall; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
1,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1 lb.`}</p></p>
    <a id="boots-of-skating"></a><p><span style={{
        "fontWeight": "bold"
      }}>Boots of Skating:</span>
These boots allow the wearer to slide along the
ground at will as if using the <a href="psionicPowersQtoW.html#skate" style={{
        "color": "rgb(87, 158, 182)"
      }}>skate</a> power.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
1st; Craft Universal Item, skate; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
7,000
gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1 lb.`}</p></p>
    <a id="boots-of-stomping"></a><p><span style={{
        "fontWeight": "bold"
      }}>Boots of Stomping:</span>
These boots allow the wearer to use <a href="psionicPowersQtoW.html#stomp" style={{
        "color": "rgb(87, 158, 182)"
      }}>stomp</a>
as the
power three times per day (Reflex save DC 13) when the wearer stomps
her feet.
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 3rd;
Craft Universal Item, stomp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
600 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1 lb.`}</p></p>
    <a id="boots-of-temporal-acceleration"></a><p><span style={{
        "fontWeight": "bold"
      }}>Boots of Temporal
Acceleration:</span> These boots allow the wearer to speed
up his own time frame for 2 rounds as if using the power <a href="psionicPowersQtoW.html#temporal-acceleration" style={{
        "color": "rgb(87, 158, 182)"
      }}>temporal
acceleration</a>. The wearer can use this power once per day.
      <p>{`Strong psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
15th; Craft Universal Item, temporal
acceleration; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 43,200
gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1 lb.`}</p></p>
    <a id="crawling-tattoos"></a><p><span style={{
        "fontWeight": "bold"
      }}>Crawling Tattoos:</span>
Crawling tattoos resemble standard psionic tattoos,
but their effects are often harmful rather than beneficial. Like a
psionic tattoo, a crawling tattoo can be scribed only with a power of
no higher than 3rd level that targets one or more creatures. Exceptions
are the <a href="psionicPowersAtoC.html#body-adjustment" style={{
        "color": "rgb(87, 158, 182)"
      }}>body adjustment</a> power, which can
be scribed even though it
affects only the manifester, and telepathy (compulsion) powers, which
cannot be scribed at all. Powers that normally have an area affect only
one target if scribed in a crawling tattoo. Powers with an experience
point requirement cannot be scribed into a crawling tattoo. Otherwise,
crawling tattoos are treated as psionic tattoos until they are
activated by the wearer.</p>
    <p>The wearer of a crawling tattoo can tap the tattoo as a
standard action
that provokes attacks of opportunity, mentally specifying a target (to
which the wearer must have line of sight). Instead of manifesting its
stored power, the tattoo animates, drops to the ground, and scuttles
toward the target. The original wearer need no longer concentrate on
the tattoo once animated.</p>
    <p>The animated tattoo moves toward its designated target in the
same
round when it is activated. Treat it as a Fine construct that has AC
18, 10 hit points, a hardness of 5, speed 30 feet, and a bonus on
attack rolls equal to the wearer&#8217;s manifester level + his key ability
modifier. Crawling tattoos, unlike true constructs, are subject to
illusions, darkness, fog, and similar effects. Should the target be
killed, teleport away, or otherwise absent itself before the animated
tattoo reaches it, the wearer can reclaim the tattoo. If it is
destroyed, a crawling tattoo shatters and evaporates.</p>
    <p>A crawling tattoo must enter the target&#8217;s square to attack and
thus
provokes attacks of opportunity as it passes through the target&#8217;s
threatened area. The tattoo makes one touch attack&nbsp; per round
thereafter until it strikes its target or is destroyed. On a successful
attack, the power scribed in the crawling tattoo affects the target if
the target fails the appropriate saving throw; however, powers that
normally allow a Reflex saving throw automatically affect the touched
target. Crawling tattoos can ferry beneficial powers as well as harmful
ones, and a target can allow the tattoo&#8217;s touch attack to succeed if he
or she desires.</p>
    <p>Two examples of crawling tattoos are described below; many
more types
are possible.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Market</span> <span style={{
        "fontStyle": "italic"
      }}>Price</span>: The cost of a crawling
tattoo depends on the level of the
power scribed in it.
    </p>
    <a id="crawling-tattoo-market-price"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "verticalAlign": "bottom",
            "width": "100px"
          }}>Power
Level</th>
          <td style={{
            "verticalAlign": "bottom",
            "width": "120px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Market Price</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>50 gp
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>300 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>750 gp
          </td>
        </tr>
      </tbody>
    </table>
    <a id="crawling-tattoo-of-concussion"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crawling Tattoo of
Concussion:</span> This crawling tattoo delivers a <a href="psionicPowersAtoC.html#concussion" style={{
        "color": "rgb(87, 158, 182)"
      }}>concussion</a> effect, dealing 1d6
points of damage to a creature hit by
its touch attack.
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 1st;
Craft Universal Item, concussion blast; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 50 gp.`}</p></p>
    <a id="crawling-tattoo-of-energy-bolt"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crawling Tattoo of Energy
Bolt:</span> Each of these tattoos is keyed to one
energy type: cold, electricity, fire, or sonic. This crawling tattoo
delivers the <a href="psionicPowersDtoF.html#energy-bolt" style={{
        "color": "rgb(87, 158, 182)"
      }}>energy bolt</a> power, dealing 5d6
points of damage of its
energy type.
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, energy bolt; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
750 gp.`}</p></p>
    <a id="crystal-anchor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Crystal Anchor:</span> A
crystal anchor is a shaft of transparent crystal 4 to
5 feet in length, with a sharpened, spearlike head on one end. It
ensnares the minds of those within range who manifest powers of a
specified psionic discipline. The triggering discipline is specified at
the time of creation.</p>
    <p>A crystal anchor is activated by plunging the sharp head into
the
ground, leaving the shaft freestanding. It projects an invisible,
30-foot-radius field. If a power from the specified discipline is
manifested within this range, the manifester must succeed on a Will
save (DC 15 + power level) or become subject to an effect similar to <a href="psionicPowersAtoC.html#brain-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>brain lock</a>, except that the
effect is permanent until dispelled or
dismissed by the individual who initially drove the crystal anchor into
the earth. Any anchor can be pulled out of the ground and reused at a
later time.</p>
    <p>When a crystal anchor successfully ensnares a target, a
slender
filament of insubstantial ectoplasm connects the victim to the shaft,
even one outside the original area of the field.</p>
    <p>A crystal anchor can attempt to ensnare a victim only five
times per
day. Previously caught targets can remain caught day after day and do
not count against this daily limit on attempts.</p>
    <p>An anchor&#8217;s owner can designate certain creatures to be
unaffected by
the anchor; such creatures need not make a saving throw and do not
count against the daily limit on attempts. One crystal anchor can
ensnare multiple targets simultaneously, and multiple crystal anchors
can share overlapping fields.</p>
    <p>Anchors that activate when a specific power is manifested in
range are
also possible, but are rarer.</p>
    <p>A crystal anchor has AC 7, 15 hit points, a hardness of 8, and
a break
DC of 20.</p>
    <a id="body-crystal-anchor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Body Crystal Anchor:</span>
Those who manifest psychometabolism powers are
affected as described above.
      <p>{`Faint psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
5th; Craft Universal Item, brain lock,
psionic contingency, any psychometabolism power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 24,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 3 lb.`}</p></p>
    <a id="comprehension-crystal-anchor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Comprehension Crystal Anchor:</span>
Those who manifest clairsentience powers
are affected as described above.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, brain lock, psionic
contingency, any clairsentience power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 24,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 3 lb.`}</p></p>
    <a id="creation-crystal-anchor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Creation Crystal Anchor:</span>
Those who manifest metacreativity powers are
affected as described above.
      <p>{`Faint metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, brain lock, psionic
contingency, any metacreativity power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 24,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 3 lb.`}</p></p>
    <a id="energy-crystal-anchor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Energy Crystal Anchor:</span>
Those who manifest psychokinesis powers are
affected as described above.
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, brain lock, psionic
contingency, any psychokinesis power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
24,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 3 lb.`}</p></p>
    <a id="ghost-crystal-anchor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Ghost Crystal Anchor:</span>
Those who manifest <a href="psionicPowersQtoW.html#remote-viewing" style={{
        "color": "rgb(87, 158, 182)"
      }}>remote viewing</a> are affected as
described above.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, brain lock, psionic
contingency, remote viewing; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
24,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 3 lb.`}</p></p>
    <a id="mind-crystal-anchor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Mind Crystal Anchor:</span>
Those who manifest telepathy powers are affected
as described above.
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, brain lock, psionic
contingency, any telepathy power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
24,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 3 lb.`}</p></p>
    <a id="travel-crystal-anchor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Travel Crystal Anchor:</span>
Those who manifest psychoportation powers are
affected as described above.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
5th; Craft Universal Item, brain lock,
psionic contingency, any psychoportation power; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 24,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{`
3 lb.`}</p></p>
    <a id="crystal-masks"></a><p><span style={{
        "fontWeight": "bold"
      }}>Crystal Masks:</span> Each of
the various types of crystal masks fits over the
face of any Medium or Small humanoid, with slits for eyes and nostrils.
The mask is transparent, although it distorts the wearer&#8217;s features.
Leather straps attached to small holes on the sides of a mask enable
the owner to affix the mask to his face.</p>
    <p>Crystal masks confer special abilities or improved skills on
their
wearers.</p>
    <p>A crystal mask is treated as eyewear or goggles for the
purpose of
determining which items can be worn on the body.</p>
    <a id="crystal-mask-of-detection"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Detection:</span>
The wearer of a crystal mask of detection
can make <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> checks with a +10
competence bonus.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 10 ranks in Search; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="crystal-mask-of-discernment"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Discernment:</span>
The wearer of a crystal mask of
discernment can make <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> checks with a +10
insight bonus.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 10 ranks in Sense Motive; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="crystal-mask-of-dread"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Dread:</span>
The wearer of a crystal mask of dread can
attempt to frighten other beings to her will. The wearer makes all <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>
checks with a +10
competence bonus.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 10 ranks in Intimidate; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="crystal-mask-of-insightful-detection"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Insightful
Detection:</span> The wearer of a crystal mask of
insightful detection can make <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> checks and <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
checks checks with
a +9 insight bonus.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 9 ranks in both Search and Spot; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
20,250 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="crystal-mask-of-knowledge"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Knowledge:</span>
Each of the various crystal masks of
knowledge grants the wearer a +5 competence bonus on a specific type of
      <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> check.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 3rd;
Craft Universal Item, creator must have 5
ranks in the Knowledge skill of the appropriate type; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 2,500 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="crystal-mask-of-languages"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Languages:</span>
Each of the various crystal masks of
language grants the wearer the ability to speak and write five
different languages.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 3rd;
Craft Universal Item, creator must have
the Speak Language skill for each of five languages; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 2,500 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="crystal-mask-of-mindarmor"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Mindarmor:</span>
A crystal mask of mindarmor grants the
wearer a +4 insight bonus on all Will saving throws.
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, conceal thoughts; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,667 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="crystal-mask-of-psionic-craft"></a><p><span style={{
        "fontStyle": "italic"
      }}>Crystal Mask of Psionic
Craft:</span> The wearer of a crystal mask of psionic
craft makes all <a href="psionicSkills.html#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a> checks with a +10
competence bonus.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 10 ranks in Psicraft; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="eyes-of-expanded-vision"></a><p><span style={{
        "fontWeight": "bold"
      }}>Eyes of Expanded Vision:</span>
These crystal lenses fit over the user&#8217;s eyes
to expand his field of vision. Flanking opponents gain only a +1 bonus
when attacking the user instead of +2 (rogues still get their full
sneak attack damage because the wearer is still flanked). The wearer
gains a +1 enhancement bonus on Spot checks but takes a -2 penalty on
saves against gaze attacks.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 1st;
Craft Universal Item, ubiquitous vision; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 3,000 gp.`}</p></p>
    <a id="eyes-of-power-leech"></a><p><span style={{
        "fontWeight": "bold"
      }}>Eyes of Power Leech:</span>
These crystal lenses fit over the user&#8217;s eyes.
Once per day, the wearer is able to drain power points from another
psionic creature or character by meeting its gaze as if using <a href="psionicPowersGtoP.html#power-leech" style={{
        "color": "rgb(87, 158, 182)"
      }}>power
leech</a>. A selected target within 40 feet must succeed on a DC 16
Will
save, or a connection of crackling energy is formed between the wearer
and the victim. The connection drains 1d6 power points from the target
for every round in which the wearer maintains concentration (to a
maximum of 7 rounds). The wearer gains 1 power point from those drained
from the target each round (subject to his normal maximum; points he
cannot gain immediately are lost).
      <p>{`If the wearer has only one lens, the DC of the Will save is 14 instead
of 16.`}</p>
      <p>{`Eyes of power leech have no effect on nonpsionic targets or psionic
creatures with a current power point reserve of 0.`}</p>
      <p>{`Moderate telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 7th;
Craft Universal Item, power leech; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,080 gp.`}</p></p>
    <a id="eyes-of-power-leech-vampiric"></a><p><span style={{
        "fontWeight": "bold"
      }}>Eyes of Power Leech, Vampiric:</span>
As eyes of power leech, except the
wearer gains drained power points from the subject even if doing so
would bring him over his normal maximum. The wearer of vampiric eyes of
power leech can maintain concentration for up to 13 rounds. Power
points the wearer gains in excess of his maximum fade after 8 hours if
they are not spent before that time.
      <p>{`Strong telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 15th;
Craft Universal Item, bend reality, power
leech; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 20,160 gp.`}</p></p>
    <a id="gloves-of-object-reading"></a><p><span style={{
        "fontWeight": "bold"
      }}>Gloves of Object Reading:</span>
While wearing these gloves, the user can
learn the details of an inanimate object&#8217;s previous owner if he handles
the object, as if manifesting the <a href="psionicPowersGtoP.html#object-reading" style={{
        "color": "rgb(87, 158, 182)"
      }}>object reading</a> power.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 1st;
Craft Universal Item, object reading; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
3,000 gp.`}</p></p>
    <a id="gloves-of-titans-grip"></a><p><span style={{
        "fontWeight": "bold"
      }}>Gloves of Titan&#8217;s Grip:</span>
While wearing these oversize gloves, the user
gains a +8 enhancement bonus on <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> checks. The power is
activated
when the user enters a grapple. The enhancement bonus lasts for a
maximum of 7 rounds per use, up to three uses per day.
      <p>{`Moderate psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
9th; Craft Universal Item, grip of iron; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 14,000 gp.`}</p></p>
    <a id="mirror-of-mind-switch"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mirror of Mind Switch:</span>
This small hand mirror manifests its power once
per day against a creature that sees its reflection within it, as a
gaze attack with a range of 30 feet. Potential victims can attempt to
avert their eyes or look away as they would from a normal gaze attack.
A creature meeting its own gaze in the mirror must succeed on a DC 19
Will save, or its mind and the mind of the mirror&#8217;s wielder switch, as
if by the <a href="psionicPowersGtoP.html#mind-switch" style={{
        "color": "rgb(87, 158, 182)"
      }}>mind switch</a> power. Because the
mirror is small, only a single
creature can meet its own gaze. The wielder of the mirror is not
affected by seeing himself.
      <p>{`Moderate telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 11th;
Craft Universal Item, mind switch; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
19,800 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="mirror-of-suggestion"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mirror of Suggestion:</span>
This small hand mirror manifests its power up to
twice per day against creatures that see their reflections within it,
as a gaze attack with a range of 30 feet. Potential victims can attempt
to avert their eyes or look away as they would from a normal gaze
attack. A creature meeting its own gaze in the mirror must succeed on a
DC 13 Will save or be affected by a <a href="psionicPowersQtoW.html#suggestion-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>suggestion</a> chosen by the
mirror&#8217;s
wielder. Because the mirror is small, only a single creature can meet
its own gaze each round. The wielder of the mirror is not affected by
seeing herself.
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 3rd;
Craft Universal Item, psionic suggestion; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 3,600 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="mirror-of-time-hop"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mirror of Time Hop:</span>
This small hand mirror manifests its power up to
twice per day against creatures that see their reflections within it,
as a gaze attack with a range of 30 feet. Potential victims can attempt
to avert their eyes or look away as they would from a normal gaze
attack. A creature meeting its own gaze in the mirror must succeed on a
DC 14 Will save or be pushed forward in time by 5 rounds, as if subject
to the <a href="psionicPowersQtoW.html#time-hop" style={{
        "color": "rgb(87, 158, 182)"
      }}>time hop</a> power. Because the
mirror is small, only a single
creature can meet its own gaze each round. The wielder of the mirror is
not affected by seeing herself.
      <p>{`Faint psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
5th; Craft Universal Item, time hop; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
9,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 1/2 lb.`}</p></p>
    <a id="pearl-brain-lock"></a><p><span style={{
        "fontWeight": "bold"
      }}>Pearl, Brain Lock:</span>
This tiny pearly sphere speeds toward its target
when hurled by its owner. He can hurl the pearl at any target within
130 feet that he can see and to which he has line of effect. If the
target fails a DC 13 Will saving throw, the pearl seems to impact and
then enter the flesh of the target. The target stands mentally
paralyzed, as if by <a href="psionicPowersAtoC.html#brain-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>brain lock</a>, for 3 rounds. The
use destroys the
item.
      <p>{`Faint telepathy: `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 3rd;
Craft Universal Item, brain lock; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
300 gp.`}</p></p>
    <a id="pearl-breath-crisis"></a><p><span style={{
        "fontWeight": "bold"
      }}>Pearl, Breath Crisis:</span>
This tiny pearly sphere speeds toward its target
when hurled by its owner. He can hurl the pearl at any target within
150 feet that he can see and to which he has line of effect. If the
target fails a DC 14 Will saving throw, the pearl seems to impact and
then enter the flesh of the target. The target is affected as if by <a href="psionicPowersAtoC.html#crisis-of-breath" style={{
        "color": "rgb(87, 158, 182)"
      }}>crisis of breath</a> for 5 minutes.
The use destroys the item.
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, crisis of breath; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
750 gp.`}</p></p>
    <a id="pearl-mind-seed"></a><p><span style={{
        "fontWeight": "bold"
      }}>Pearl, Mind Seed:</span> This
tiny pearly sphere instantly takes on the skin
tone and texture of whatever creature handles it. It speeds toward its
target when hurled by its owner. He can hurl the pearl at any target
within 150 feet that he can see and to which he has line of effect. If
the target fails a DC 22 Will saving throw, the pearl seems to impact
and then enter the flesh of the target. The target is affected as if by
a <a href="psionicPowersGtoP.html#mind-seed" style={{
        "color": "rgb(87, 158, 182)"
      }}>mind seed</a> power, with the
pearl&#8217;s wielder acting as the manifester.
The use destroys the item.
      <p>{`Strong telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 15th;
Craft Universal Item, mind seed; Cost 6,000
gp + 2,500 XP; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 18,500
gp.`}</p></p>
    <a id="pearl-personality-parasite"></a><p><span style={{
        "fontWeight": "bold"
      }}>Pearl, Personality Parasite:</span>
This tiny pearly sphere speeds toward its
target when hurled by its owner. He can hurl the pearl at any target
within 130 feet that he can see and to which he has line of effect. If
the target fails a DC 16 Will saving throw, the pearl seems to impact
and then enter the flesh of the target. The target is affected as if by
      <a href="psionicPowersGtoP.html#personality-parasite" style={{
        "color": "rgb(87, 158, 182)"
      }}>personality parasite</a> for 5
rounds. The use destroys the item.
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 7th;
Craft Universal Item, personality parasite; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 1,400 gp.`}</p></p>
    <a id="psionatrix"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psionatrix:</span> Each of
these multifaceted crystals is worn as an amulet.
Unless noted otherwise, a psionatrix adds a +1 enhancement bonus to the
save DC when the wearer uses powers of the discipline to which the
psionatrix is keyed.</p>
    <a id="psionatrix-of-clairsentience"></a><p><span style={{
        "fontStyle": "italic"
      }}>Psionatrix of clairsentience:</span>
Blue light glimmers in this crystal&#8217;s
heart.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Universal Item, creator must be
a seer; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 8,000 gp.`}</p></p>
    <a id="psionatrix-of-metacreativity"></a><p><span style={{
        "fontStyle": "italic"
      }}>Psionatrix of metacreativity:</span>
Green light glimmers in this crystal&#8217;s
heart.
      <p>{`Moderate metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Universal Item, creator must be
a shaper; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 8,000 gp.`}</p></p>
    <a id="psionatrix-of-psychokinesis"></a><p><span style={{
        "fontStyle": "italic"
      }}>Psionatrix of psychokinesis:</span>
Red light glimmers in this crystal&#8217;s heart.
      <p>{`Moderate psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Universal Item, creator must be a
kineticist; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 8,000 gp.`}</p></p>
    <a id="psionatrix-of-psychometabolism"></a><p><span style={{
        "fontStyle": "italic"
      }}>Psionatrix of
psychometabolism:</span> Violet light glimmers in this crystal&#8217;s
heart.
      <p>{`Moderate psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Universal Item, creator must
be an egoist; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 8,000 gp.`}</p></p>
    <a id="psionatrix-of-psychoportation"></a><p><span style={{
        "fontStyle": "italic"
      }}>Psionatrix of
psychoportation:</span> Yellow-red light glimmers in this
crystal&#8217;s heart.
      <p>{`Moderate psychoportation; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Universal Item, creator must be
a nomad; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 8,000 gp.`}</p></p>
    <a id="psionatrix-of-telepathy"></a><p><span style={{
        "fontStyle": "italic"
      }}>Psionatrix of telepathy:</span>
Piercing yellow light shines from this
crystal&#8217;s heart.
      <p>{`Moderate telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 8th;
Craft Universal Item, creator must be a
telepath; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 8,000 gp.`}</p></p>
    <a id="psionic-restraints"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psionic Restraints:</span>
Each of the various psionic restraints is an iron
cuff that cunningly locks around the wrist (DC 27 <a href="skillsAll.html#open-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>Open
Lock</a> check). The
cuff limits the total number of power points a psionic creature wearing
it can use in 1 round (regardless of the creature&#8217;s total power point
reserve), or completely damps the ability to use psionics. All types of
psionic restraints prevent the free manifesting of powers.
    </p>
    <a id="table-psionic-restraints-allowed-power-points-per-round"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "verticalAlign": "bottom",
            "width": "100px"
          }}>Restraint
Type
          </th>
          <td style={{
            "verticalAlign": "bottom",
            "width": "120px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Allowed Power Points/Round</span>
          </td>
          <td style={{
            "verticalAlign": "bottom"
          }}><span style={{
              "fontWeight": "bold"
            }}>Market Price</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>Lesser
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1,000 gp
          </td>
        </tr>
        <tr>
          <td>Average
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6,000 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>Greater
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>12,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Damping
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>24,000 gp
          </td>
        </tr>
      </tbody>
    </table>
    <p>Strong psychokinesis; <span style={{
        "fontStyle": "italic"
      }}>ML</span>
16th; Craft Universal Item, bend reality,
dispel psionics; <span style={{
        "fontStyle": "italic"
      }}>Weight</span> 1 lb.</p>
    <p><a href="http://www.wizards.com/dnd/images/xph_gallery/33553.jpg" style={{
        "fontWeight": "bold"
      }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><a id="psychoactive-skin"></a><span style={{
        "fontWeight": "bold"
      }}>Psychoactive Skins:</span>
Psychoactive skins (also sometimes called
ectoshells) are fistsized globs of psionically charged ectoplasm. As a
standard action, a psychoactive skin spreads over and covers a Medium
or smaller creature that projects the proper command thought; the same
command thought causes the skin to shrink back down to its former size.
Each of the various psychoactive skins is charged with one of a wide
array of powers that constantly affect the wearer.</p>
    <p>A deployed skin completely covers the wearer and all his
equipment like
a second skin, allowing the wearer to see, hear, and breathe normally.
It rolls away from various parts of the body as needed, such as when
the wearer needs to eat or access a backpack. Held items or items
specifically excluded are not covered by a psychoactive skin.</p>
    <p>Up to three skins can be worn simultaneously, although only
the
outermost is active in any given round (the powers of hidden skins
cannot be manifested). Skin layers can be changed with a command
thought as a standard action, which causes a lower-layer skin to come
to the surface.</p>
    <a id="skin-of-the-chameleon"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of the Chameleon:</span>
This psychoactive skin adjusts to surrounding
textures and colors, continually granting the wearer a +10 enhancement
bonus on <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> checks.
      <p>{`Faint psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
3rd; Craft Universal Item, chameleon; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
18,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-the-claw"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of the Claw:</span>
This psychoactive skin benefits only a wearer who has
levels in psychic warrior. If such a character wears this skin, he can
activate the <a href="psionicPowersAtoC.html#claws-of-the-beast" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the beast</a> power at
will, as a free action that
does not provoke attacks of opportunity. He can also dismiss the claws
equally quickly.
      <p>{`Faint psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
4th; Craft Universal Item, claws of the
beast; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 16,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-the-defender"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of the Defender:</span>
This psychoactive skin continually grants the
wearer a +4 bonus to natural armor.
      <p>{`Faint psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
5th; Craft Universal Item, thicken skin; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 32,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-fiery-response"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of Fiery Response:</span>
This psychoactive skin answers the first attack
made against the wearer in each round with an automatically manifested
and targeted &#8220;ectoburst&#8221; against the attacker. The burst ejects from
the skin on the wearer&#8217;s turn, making a ranged touch attack as
described in the <a href="psionicPowersDtoF.html#energy-retort" style={{
        "color": "rgb(87, 158, 182)"
      }}>energy retort</a> power, but the
energy type chosen is
always fire. If the attack hits, the target takes 2d6 points of fire
damage as the ectoburst flames up and is consumed. This attack does not
in any way hinder the wearer, count against her total actions for the
round, or provoke attacks of opportunity.
      <p>{`Faint psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, energy retort; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
60,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-the-hero"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of the Hero:</span>
This psychoactive skin continually grants the wearer
a +3 deflection bonus to Armor Class, a +3 resistance bonus on all
saving throws, and a +3 enhancement bonus on attack rolls.
      <p>{`Strong psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
18th; Craft Universal Item, bend reality; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 77,500 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-iron"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of Iron:</span> This
psychoactive skin affects the wearer as the <a href="psionicPowersGtoP.html#iron-body-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic
iron body</a> power up to three times per day for 15 minutes with each
use.
When the skin&#8217;s power is not active, it has no protective qualities.
Activating the psionic iron body effect is a standard action that
provokes attacks of opportunity.
      <p>{`Strong metacreativity; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
15th; Craft Universal Item, psionic iron
body; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 129,600 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-nimbleness"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of Nimbleness:</span>
This psychoactive skin continually grants the
wearer a +10 competence bonus on <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble </a>checks.
      <p>{`Moderate psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
8th; Craft Universal Item, creator must
have 10 ranks in Tumble; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-proteus"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of Proteus:</span> This
psychoactive skin continually affects the wearer
as the <a href="psionicPowersGtoP.html#metamorphosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>metamorphosis</a> power. While in a
form other than his natural
form, the wearer does not appear to be wearing the skin.
      <p>{`Moderate psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, metamorphosis; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 84,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-the-psion"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of the Psion:</span>
This psychoactive skin grants the wearer 7 bonus
power points per day and power resistance 21.
      <p>{`Strong clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
17th; Craft Universal Item, power resistance; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 151,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-the-spider"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of the Spider:</span>
This psychoactive skin continually grants the
wearer a +20 competence bonus on <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a> checks and continually
affects
the wearer as the <a href="psionicPowersAtoC.html#body-equilibrium" style={{
        "color": "rgb(87, 158, 182)"
      }}>body equilibrium</a> power. Three
times per day, the
wearer can manifest <a href="psionicPowersDtoF.html#entangling-ectoplasm" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangling ectoplasm</a> against a
target within 30
feet.
      <p>{`Faint psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
3rd; Craft Universal Item, creator must have
10 ranks in Climb, body equilibrium, entangling ectoplasm; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 79,080
gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="skin-of-the-troll"></a><p><span style={{
        "fontStyle": "italic"
      }}>Skin of the Troll:</span>
This psychoactive skin continually allows a living
wearer to heal more quickly. The wearer heals 5 points of damage per
minute. Except for the slower rate of healing, this skin affects its
wearer as if the wearer were under the effect of a continual true
metabolism power.
      <p>{`Strong psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
17th; Craft Universal Item, true
metabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 61,200 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="ring-of-self-sufficiency"></a><p><span style={{
        "fontWeight": "bold"
      }}>Ring of Self-Sufficiency:</span>
This crystalline ring continually grants the
wearer a +10 competence bonus on <a href="psionicSkills.html#autohypnosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>Autohypnosis</a> checks.
      <p>{`Moderate psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 10 ranks in Autohypnosis; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp.`}</p></p>
    <a id="shards"></a><p><span style={{
        "fontWeight": "bold"
      }}>Shards:</span> These
universal items are tiny fragments of cast-off crystal
imprinted with minuscule bits of psionic power. Using a shard requires
grasping it and projecting a command thought as a standard action (most
shards telepathically whisper their command word into the minds of
living creatures who handle them). A shard grants a temporary
competence bonus on one specific skill, ranging from +1 to +10. The
granted bonus lasts until the skill is used or 10 rounds pass,
whichever comes first. Once its effect is activated, a shard
disintegrates immediately, whether or not the bonus granted by the
shard is eventually used.
      <p>{`Moderate psychometabolism; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have ranks in the specified skill at least equal to the imprinted
bonus; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 10 gp (+1), 40
gp (+2), 90 gp (+3), 160 gp (+4), 250 gp
(+5), 360 gp (+6), 490 gp (+7), 640 gp (+8), 810 gp (+9), or 1,000 gp
(+10). `}</p></p>
    <a id="third-eyes"></a><p><span style={{
        "fontWeight": "bold"
      }}>Third Eyes:</span> These
objects appear as small crystals, always with at
least one wide, flat facet, and contain a variety of powers. When the
owner uses the proper command thought, the crystal adheres to the
center of his forehead (the same command thought causes the third eye
to disengage). Only one third eye can be worn at a time.</p>
    <p>A third eye is treated as eyewear or goggles for the purpose
of
determining which items can be worn on the body.</p>
    <a id="third-eye-aware"></a><p><span style={{
        "fontStyle": "italic"
      }}>Aware:</span> This kind of
third eye continually grants the wearer a +10
competence bonus on <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> checks.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 10 ranks in Spot; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp.`}</p></p>
    <a id="third-eye-conceal"></a><p><span style={{
        "fontStyle": "italic"
      }}>Conceal:</span> While worn,
a third eye conceal protects the wearer from view
by all devices, powers, and spells that detect, inf luence, or read
emotions or thoughts. This power protects against all mind-affecting
powers and effects as well as information-gathering by clairsentience
powers or effects (except for <a href="psionicPowersGtoP.html#metafaculty" style={{
        "color": "rgb(87, 158, 182)"
      }}>metafaculty</a>); this item affects
the
wearer as if he enjoyed the benefit of the <a href="psionicPowersGtoP.html#mind-blank-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic mind blank</a> power.
      <p>{`Strong telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 15th;
Craft Universal Item, psionic mind blank; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 120,000 gp.`}</p></p>
    <a id="third-eye-concentrate"></a><p><span style={{
        "fontStyle": "italic"
      }}>Concentrate: </span>This
kind of third eye continually grants the wearer a +10
competence bonus on <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> checks.
      <p>{`Moderate telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 7th;
Craft Universal Item, creator must have 10
ranks in Concentration; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp.`}</p></p>
    <a id="third-eye-dominate"></a><p><span style={{
        "fontStyle": "italic"
      }}>Dominate: </span>The wearer
of a third eye dominate can attempt to dominate a
subject as with the power <a href="psionicPowersDtoF.html#dominate-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic dominate</a>, augmented to
target any
creature type it can affect, once per day (save DC 18).
      <p>{`Strong telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 15th;
Craft Universal Item, psionic dominate; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 120,000 gp.`}</p></p>
    <a id="third-eye-expose"></a><p><span style={{
        "fontStyle": "italic"
      }}>Expose: </span>The wearer
of this kind of third eye always knows when someone
lies directly to him.
      <p>{`Strong telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 15th;
Craft Universal Item, bend reality; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
112,000 gp.`}</p></p>
    <a id="third-eye-gather"></a><p><span style={{
        "fontStyle": "italic"
      }}>Gather: </span>This kind of
third eye continually grants the wearer a +10
competence bonus on <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> checks.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, creator must
have 10 ranks in Gather Information; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
10,000 gp.`}</p></p>
    <a id="third-eye-penetrate"></a><p><span style={{
        "fontStyle": "italic"
      }}>Penetrate:</span> While
worn, a third eye penetrate grants the wearer a +2
bonus on manifester level checks to overcome a creature&#8217;s power
resistance.
      <p>{`Strong clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
15th; Craft Universal Item, bend reality; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 8,000 gp.`}</p></p>
    <a id="third-eye-powerthieve"></a><p><span style={{
        "fontStyle": "italic"
      }}>Powerthieve:</span> While
worn, a third eye powerthieve allows the wearer to
borrow one power from a psionic target within 40 feet once per day. If
the target fails a DC 16 Will save, it instantly loses one power of the
wearer&#8217;s choice, and the wearer instantly gains temporary knowledge of
this power.
      <p>{`The wearer can manifest the borrowed power normally if she has
sufficient power points to pay for its cost. The wearer retains
knowledge of the power for up to 70 minutes, at which time she loses
knowledge of the power and the former owner regains it, regardless of
the distance between them. If the former owner is dead, the wearer
still loses the borrowed power.`}</p>
      <p>{`Moderate telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 7th;
Craft Universal Item, thieving mindlink; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 10,080 gp.`}</p></p>
    <a id="third-eye-repudiate"></a><p><span style={{
        "fontStyle": "italic"
      }}>Repudiate:</span> While
worn, this powerful item allows the wearer to manifest <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a> once per day
with a +20 modifier on the dispel check
(the wearer uses the +20 modifier in place of his manifester level).
      <p>{`Strong psychokinesis; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
20th; Craft Universal Item, dispel psionics; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 43,200 gp.`}</p></p>
    <a id="third-eye-sense"></a><p><span style={{
        "fontStyle": "italic"
      }}>Sense:</span> The wearer of
this kind of third eye can manifest <a href="psionicPowersAtoC.html#clairvoyant-sense" style={{
        "color": "rgb(87, 158, 182)"
      }}>clairvoyant
sense</a> at will.
      <p>{`Faint clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 3rd;
Craft Universal Item, clairvoyant sense; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 24,000 gp.`}</p></p>
    <a id="third-eye-view"></a><p><span style={{
        "fontStyle": "italic"
      }}>View:</span> The wearer of
this kind of third eye can spin a quasireal version
of himself and send it over virtually any distance or into other planes
of existence, as if manifesting the <a href="psionicPowersQtoW.html#remote-viewing" style={{
        "color": "rgb(87, 158, 182)"
      }}>remote viewing</a> power, once per
day.
      <p>{`Moderate clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
7th; Craft Universal Item, remote viewing; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 10,180 gp.`}</p></p>
    <a id="torc-of-free-will"></a><p><span style={{
        "fontWeight": "bold"
      }}>Torc of Free Will:</span>
This item is a band inlaid with precious metal, worn
around the neck or upper arm. The wearer of a torc of free will is not
affected by the <a href="psionicPowersAtoC.html#brain-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>brain lock</a> power or items that
produce brain lock
effects (such as crystal anchors).
      <p>{`A torc is treated as an amulet for the purpose of determining which
items can be worn on the body.`}</p>
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 3rd;
Craft Universal Item, brain lock; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
6,000
gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="torc-of-leech-freedom"></a><p><span style={{
        "fontWeight": "bold"
      }}>Torc of Leech Freedom:</span>
This item is a band inlaid with precious metal,
worn around the neck or upper arm. The wearer of a torc of leech
freedom automatically resists up to two uses of <a href="psionicPowersGtoP.html#power-leech" style={{
        "color": "rgb(87, 158, 182)"
      }}>power leech</a> against him
per day.
      <p>{`A torc is treated as an amulet for the purpose of determining which
items can be worn on the body.`}</p>
      <p>{`Faint telepathy; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{` 5th;
Craft Universal Item, power leech; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{`
12,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>
    <a id="torc-of-power-preservation"></a><p className="initial"><span style={{
        "fontWeight": "bold"
      }}>Torc of Power
Preservation:</span> This item is a band inlaid with precious
metal, worn around the neck or upper arm. The wearer of a torc of power
preservation manifests all powers by paying power points equal to the
standard cost minus 1 (minimum of 1).
      <p>{`A torc is treated as an amulet for the purpose of determining which
items can be worn on the body.`}</p>
      <p>{`Strong clairsentience; `}<span style={{
          "fontStyle": "italic"
        }}>{`ML`}</span>{`
15th; Craft Universal Item, bend reality; `}<span style={{
          "fontStyle": "italic"
        }}>{`Price`}</span>{` 36,000 gp; `}<span style={{
          "fontStyle": "italic"
        }}>{`Weight`}</span>{` 2 lb.`}</p></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      